<template>
  <v-container
    fluid
    class="pa-0"
  >
    <v-card
      v-if="object"
      class="ma-0 user_manage-accounts"
    >
      <v-card-actions
        class="justify-space-between px-6 py-5"
      >
        <h2>
          {{ `Edit Keyword #${object.id}` }}
        </h2>
        <div class="action-buttons">
          <v-btn
            color="teal lighten-2"
            class="ma-0"
          >
            <v-icon
              left
              color="#fff"
            >
              mdi-content-save
            </v-icon>
            Save
          </v-btn>
          <v-btn
            color="red lighten-1"
            class="my-0 ml-4 mr-0"
          >
            <v-icon
              left
              color="#fff"
            >
              mdi-delete
            </v-icon>
            Cancel
          </v-btn>
        </div>
      </v-card-actions>
      <v-divider />
      <v-tabs
        v-model="tab"
        class="px-6 py-4"
      >
        <v-tab
          v-for="item in tabs"
          :key="item.name"
        >
          {{ item.name }}
        </v-tab>
        <v-tabs-items
          v-model="tab"
          class="pt-6 pb-2"
        >
          <v-tab-item
            v-for="item in tabs"
            :key="item.name"
          >
            <component
              :is="item.component"
              :object="object"
            />
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
      <v-divider />
      <div class="px-6 py-5">
        <div class="action-buttons">
          <v-btn
            color="teal lighten-2"
            class="ma-0"
          >
            <v-icon
              left
              color="#fff"
            >
              mdi-content-save
            </v-icon>
            Save
          </v-btn>
          <v-btn
            color="red lighten-1"
            class="my-0 ml-4 mr-0"
          >
            <v-icon
              left
              color="#fff"
            >
              mdi-delete
            </v-icon>
            Cancel
          </v-btn>
        </div>
      </div>
    </v-card>
  </v-container>
</template>

<script>
  export default {
    components: {
      ManageKeywordsTab: () => import('@/components/object-keywords-details/ManageKeywordsTab'),
      KeywordCategoriesTab: () => import('@/components/object-keywords-details/KeywordCategoriesTab'),
    },

    data () {
      return {
        objects: [
          {
            id: 562,
            mainKeyword: 'Kran',
            metaKeywords: 'Pferdesport Reitartikel Reitsport',
            optionalKeywords: 'Pferdesport Reitartikel Reitsport',
            objectCount: 25,
            requestCount: 132,
            rentalFees: '5,940.00',
            averageRentalFee: '45.00',
          },
          {
            id: 563,
            mainKeyword: 'Kran 2',
            metaKeywords: 'Pferdesport Reitartikel Reitsport',
            optionalKeywords: 'Pferdesport Reitartikel Reitsport',
            objectCount: 26,
            requestCount: 133,
            rentalFees: '5,941.00',
            averageRentalFee: '46.00',
          },
          {
            id: 564,
            mainKeyword: 'Kran 3',
            metaKeywords: 'Pferdesport Reitartikel Reitsport',
            optionalKeywords: 'Pferdesport Reitartikel Reitsport',
            objectCount: 27,
            requestCount: 134,
            rentalFees: '5,942.00',
            averageRentalFee: '47.00',
          },
        ],
        object: null,
        tabs: [
          { name: 'Manage Keywords', component: 'ManageKeywordsTab' },
          { name: 'Keyword Categories', component: 'KeywordCategoriesTab' },
        ],
        tab: null,
      }
    },

    created () {
      if (this.$route.query.activeTab) {
        this.tab = this.tabs.findIndex(el => el.name.toLowerCase() === this.$route.query.activeTab.toLowerCase())
      }
      if (this.objects.length && this.objects.find(el => el.id == this.$route.params.id)) {
        this.object = this.objects.find(el => el.id == this.$route.params.id)
      }
    },
  }
</script>

<style lang="scss">

</style>
